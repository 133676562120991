import { Loading } from '@saesa/ui-kit-front';
import {
  ALZA_PRECIO_LUZ,
  CICLO_FACTURACION,
  CORTE,
  DETALLE,
  DETALLE_TICKET,
  LISTADO_TICKETS,
  POST_VENTA,
  SERVICIO_ACTIVO,
} from 'navigation';
import { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';

const RouterConfig = () => {
  /**
   * Carga de componente de MPC
   */
  const AlzaPrecioEnergia = lazy(() => import('pages/CicloFacturacion/AlzaPrecioEnergia/AlzaPrecioEnergia'));

  const CicloFacturacion = lazy(() => import('pages/CicloFacturacion/CicloFacturacion'));
  const Corte = lazy(() => import('pages/Corte/Corte'));

  const DetalleTrackingCicloComercial = lazy(
    () => import('pages/CicloFacturacion/DetalleTrackingCicloFacturacion/DetalleTrackingCicloFacturacion')
  );

  const DetalleTracking = lazy(() => import('pages/Corte/DetalleTrackingCorte/DetalleTrackingCorte'));

  const ServicioActivado = lazy(() => import('pages/Corte/ServicioActivado/ServicioActivado'));

  /** Constantes de Postventa */
  const PostVenta = lazy(() => import('pages/PostVenta/PostVenta'));
  const PostVentaListado = lazy(() => import('pages/PostVenta/Listado/Listado'));
  const PostVentaDetalle = lazy(() => import('pages/PostVenta/Detalle/Detalle'));

  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        {/* Flujos Principales */}
        <Route exact path={CICLO_FACTURACION}>
          <CicloFacturacion />
        </Route>
        <Route exact path={CORTE}>
          <Corte />
        </Route>
        <Route exact path={POST_VENTA}>
          <PostVenta />
        </Route>

        {/* Ciclo Comercial */}
        <Route exact path={`${CICLO_FACTURACION}/${DETALLE}`}>
          <DetalleTrackingCicloComercial />
        </Route>

        <Route exact path={`${CICLO_FACTURACION}/${DETALLE}/${ALZA_PRECIO_LUZ}`}>
          <AlzaPrecioEnergia />
        </Route>

        {/* Cortes */}
        <Route path={`${CORTE}/${DETALLE}`}>
          <DetalleTracking />
        </Route>

        {/* Pantallas compartidas de corte */}
        <Route path={`${CORTE}/${SERVICIO_ACTIVO}`}>
          <ServicioActivado />
        </Route>

        {/* Postventa */}
        <Route path={`${POST_VENTA}/${LISTADO_TICKETS}`}>
          <PostVentaListado />
        </Route>
        <Route path={`${POST_VENTA}/${DETALLE_TICKET}`}>
          <PostVentaDetalle />
        </Route>
      </Switch>
    </Suspense>
  );
};

export default RouterConfig;
